import axios, { InternalAxiosRequestConfig } from "axios"

import { isString } from "./any"

/**
 * Provided urls are prefixed with `/admin/` for convenience and `X-CSRF-TOKEN` is set at request time.
 */
export const adaClient = axios.create({
  withCredentials: true,
  baseURL: "/admin/",
})

/**
 * Automatically sets CSRF token and instructs API to reply with camelCased bodies.
 */
export const appClient = axios.create({
  withCredentials: true,
  baseURL: "/",
  headers: { "Key-Inflection": "camel" },
})

export const iClient = axios.create({
  withCredentials: true,
  baseURL: "/api/i/",
  headers: { "Key-Inflection": "camel" },
})

adaClient.interceptors.request.use(attachXCSRFToken)
appClient.interceptors.request.use(attachXCSRFToken)
iClient.interceptors.request.use(attachXCSRFToken)

adaClient.interceptors.request.use(forwardTracingContext)
appClient.interceptors.request.use(forwardTracingContext)
iClient.interceptors.request.use(forwardTracingContext)

/**
 * Attaches an X-CSRF-TOKEN header to an Axios request configuration if a CSRF token is found in the document's meta tags.
 *
 * @template D The type of the request data.
 * @param config The Axios request configuration object.
 * @returns The modified Axios request configuration object with the X-CSRF-TOKEN header attached.
 *
 * @throws {Error} If the request headers cannot be found or the CSRF token meta tag is missing.
 */
function attachXCSRFToken<D>(config: InternalAxiosRequestConfig<D>): InternalAxiosRequestConfig<D> {
  if (document instanceof Document) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute("content")

    if (isString(csrfToken) && config.headers) {
      config.headers["X-CSRF-TOKEN"] = csrfToken
    } else if (!config.headers) {
      console.error(new Error(`Trying to set X-CSRF-TOKEN, request header cannot be found`))
    } else {
      console.error(new Error(`meta[name="csrf-token"] could not be found!`))
    }
  }

  return config
}

/**
 * Adds tracing context headers and parameters to an Axios request configuration.
 *
 * This function extracts tracing information from the document's meta tags and the URL's query parameters,
 * and adds them to the Axios request configuration headers and parameters.
 *
 * @template D The type of the request data.
 * @param config The Axios request configuration to modify.
 * @returns The modified Axios request configuration with tracing context added.
 */
function forwardTracingContext<D>(config: InternalAxiosRequestConfig<D>): InternalAxiosRequestConfig<D> {
  if (document instanceof Document) {
    const traceId = document.querySelector('meta[name="trace-id"]')?.getAttribute("content")
    const spanId = document.querySelector('meta[name="span-id"]')?.getAttribute("content")
    const tracing = new URLSearchParams(window.location.search).get("tracing")

    if (traceId) config.headers["X-TRACE-ID"] = traceId
    if (spanId) config.headers["X-SPAN-ID"] = spanId
    if (tracing) config.headers["X-TRACING"] = tracing
  }

  return config
}
